<template>
  <div>奖金流水</div>
</template>

<script>
export default {

}
</script>

<style>

</style>